@media print {
    #header {
        display: none;
    }

    .noprint {
        display: none;
    }

    .ant-layout-content {
        padding: initial;
        margin-top: initial;
    }
}

#root {
    height: 100vh;
}

.planzeile-available {
    cursor: pointer;
    padding: 0 10px;
}

.planzeile-available:hover {
    background-color: #ddd5;
}

.planzeile-kopf-buttonbar-sticky {
    position: fixed;
    z-index: 5;
    right: 50px;
    background-color: rgba(240, 242, 245, 0.8);
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
}

.planzeile-kontrolliert {
    background-color: lightgreen;
}

.planzeile-selected {
    background-color: rgba(173, 216, 230, 1);
}

.planzeile.unsichtbar {
    display: none;
}


.radio-rezept-anfordern-modal {
    width: 100%;
    background-color: #86ADC8;
}


.radio-rezept-anfordern-modal.checked{
    background-color: #006B3A;
    color: white;
}

.img-info-marker.highlight {
    background-color: #ff0000;
}

.img-info-marker.highlight-animate {
    animation: img-info-marker-highlight 1s linear infinite;
}

@keyframes img-info-marker-highlight {
    50% {
        background-color: #ffffff;
    }
}

.datei-liste-popover-table td {
    padding: 5px;
}

.datei-liste-popover-table tr td:first-child {
    text-align: right;
    font-weight: bold;
    padding-right: 10px;
}

.datei-liste-item {
    padding: 5px;
}

.datei-liste-item:nth-child(odd),
.datei-liste-popover-table tr:nth-child(odd) {
    background-color: rgba(255, 255, 255, 0.3);
}

.datei-liste-item:nth-child(even),
.datei-liste-popover-table tr:nth-child(even) {
    background-color: rgba(255, 255, 255, 0.9);
}

.input-menge,
.input-menge .ant-input-group-addon,
.input-menge input {
    text-align: center;
    font-weight: bold;
    background-color: #51B588;

    border: none;
}

.input-menge .ant-input-group-addon {
    font-weight: initial;
}

.input-menge-leer,
.input-menge-leer .ant-input-group-addon,
.input-menge-leer input {
    background-color: #2C7AB1;
    font-weight: normal
}

.input-menge.disabled,
.input-menge.disabled .ant-input-group-addon,
.input-menge.disabled input {
    background-color: initial;
    font-weight: initial;
}

.input-menge-alternative input, .input-menge-alternative {
    background-color: #e0a434 !important;
}

.input-menge-nicht-teilbar input, .input-menge-nicht-teilbar {
    background-color: #FFADD2FF !important;
}

.bestand-row-drag-over td {
    background: #51B58866 !important;
}

.bestand-hover {
    background-color: #2C7AB166;
    cursor: grab;
}

.grabbing {
    cursor: grabbing;
}

.rotate-align-90 {
    transform: translateY(-100%) rotate(90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
}

.rotate-align-180 {
    transform: translate(-100%, 100%) rotate(180deg);
    -webkit-transform-origin: right top;
    -moz-transform-origin: right top;
    -ms-transform-origin: right top;
    -o-transform-origin: right top;
    transform-origin: right top;
}

.rotate-align-270 {
    transform: translateX(-100%) rotate(-90deg);
    -webkit-transform-origin: right top;
    -moz-transform-origin: right top;
    -ms-transform-origin: right top;
    -o-transform-origin: right top;
    transform-origin: right top;
}

#sider-dokument-popup {
    overflow: scroll;
    background-color: #f8f9fa;
    z-index: 250;
    position: fixed;
    right: 0;

    -ms-overflow-style: none;
}

#sider-dokument-popup::-webkit-scrollbar {
    display: none;
}

.planzeile.drag-over {
    background-color: lightgreen;
}

.planzeile.dragging {
    background-color: lightblue;
}

.menu-item {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    color: black;
    height: 100px;
    padding: 0 5px;
}

.menu-item.aktiv {
    background-color: rgba(44, 177, 173, 0.67);
}

.menu-item:hover {
    background-color: rgba(44, 177, 173, 0.47);
}

.planzeile.nicht-verblistert .planzeile-info-links {
    background-color: #ffadd2;
}

.planzeile.pausiert {
    background-color: #dcdcdc;
}

.planzeile.unklar {
    background-color: #fff1a9;
}

.suche-medikationsplan-list {
    position: absolute;
    width: 100%;
    background-color: rgb(134, 173, 200);
    z-index: 5;
}

.suche-medikationsplan-item {
    cursor: pointer;
    overflow: hidden;
    color: #fff;
}

.suche-medikationsplan-item.selected,
.suche-medikationsplan-item.hovered {
    color: #242424;
    overflow: visible;
    background-color: rgb(134, 173, 200);
}

.suche-medikationsplan-item.selected {
    border-bottom: solid #242424 2px;
}

.suche-medikationsplan-item .icon {
    position: absolute !important;
    left: 3px;
}

.suche-medikationsplan-item svg {
    vertical-align: initial;
}

.vertical-align-initial {
    vertical-align: initial;
}

.margin-right-10 {
    margin-right: 10px;
}

.color-red {
    color: red;
}

.icon-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.datepicker-without-input .ant-picker {
    vertical-align: baseline;
    position: absolute;
    visibility: hidden;
}

.warning {
    color: #d9363e;
    font-weight: bold;
}

.popover-invisible {
    display: none;
}

.tooltip-invisible {
    display: none;
}

.moveable.no-border .moveable-line {
    display: none;
}

.adressbuch-stammdaten .grid,
.grid-auto {
    display: grid;
    grid-template-columns: min-content auto;
    width: 100%;
    max-width: 500px;

    column-gap: 1em;
}

.flex {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.flex.space-between {
    justify-content: space-between;
}

.adressbuch-stammdaten .flex {
    display: flex;
    gap: 1em;
}

.adressbuch-stammdaten .flex input {
    flex-grow: 1;
}

.adressbuch-stammdaten .span-2 {
    grid-column: span 2;
}

.adressbuch-stammdaten textarea {
    width: 100%;
}

.adressbuch-adressen .adresse {
    position: relative;

    width: 100%;
    max-width: 800px;
}

.adressbuch-adressen .adresse.bar {
    margin-top: 2em;
}

.adressbuch-adressen .adresse.bar::before {
    content: "";
    position: absolute;
    background-color: #d8d9dc;
    width: 100%;
    height: 1px;
    top: -1em;
}

.adressbuch-adressen .adresse p {
    display: flex;
}

.adressbuch-adressen .adresse p * {
    flex-basis: 100%;
    flex-shrink: 1;
}

.adressbuch-adressen .adresse p .titel {
    flex-shrink: 2;
}

.adressbuch-kontakte .kontakt {
    position: relative;

    width: 100%;
    max-width: 800px;
}

.adressbuch-kontakte .kontakt.bar {
    margin-top: 2em;
}

.adressbuch-kontakte .kontakt.bar::before {
    content: "";
    position: absolute;
    top: -1em;
    background-color: #d8d9dc;
    width: 100%;
    height: 1px;
}

.disableOverlay {
    position: relative;
    width: 100%;
    height: 100%;
}

.disableOverlay::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1100;
    background-color: rgba(0, 0, 0, .7);

    color: #86ADC8;
    font-size: 5rem;
    font-weight: 900;
    text-align: center;

    display: grid;
    align-items: center;
}

.overflowEllipsis {
    overflow: hidden;
    text-overflow: ellipsis;
}

.table-dokument .ant-table-content {
    overflow-x: auto;
}

.anforderung-row .icon {
    position: absolute;
    top: 50%;
    right: 0;
}

.anforderung-row:hover .icon.edit {
    display: inline;
}

.ellipsis-bottom {
    overflow: visible;
}

.ellipsis-bottom:hover {
    overflow: visible;
}

.ellipsis-bottom:not(:hover)::after {
    content: "...";
    position: absolute;
    right: 50%;
    bottom: 5px;
}

.ellipsis .bg-white-hover::before {
    content: "";

    position: absolute;
    width: 100%;
    height: 100%;

    background: rgba(255,155,155,.5);
}

.link {
    cursor: pointer;
}

.buendel-table-row {
    display: flex;
}

.buendel-table-row:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.buendel-table-row .buendel-table-row-span {
    flex-grow: 1;
}

.buendel-table-row .buendel-table-row-icons {
    overflow-x: visible;
    position: absolute;
    right: 0;
    /*left: calc(100% - 2px);*/
    display: flex;
    gap: 5px;
}

.buendel-table-row .buendel-table-row-icons .icon-onhoverrow {
    padding: 4px;
    background-image: radial-gradient(rgba(255, 255, 255, 0.7), transparent 100%);
    display: none;
}

.buendel-table-row:hover .buendel-table-row-icons .icon-onhoverrow {
    display: block;
}